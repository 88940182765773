import React, { useEffect } from "react";
import { User } from "../../../app/auth/model/User";
import AuthLayout from "./components/AuthLayout";
import PublicLayout from "./components/publicLayout/PublicLayout";

interface AppLayoutProps {
  children: React.ReactNode;
  user: User | undefined;
  logout: () => void;
}
const AppLayout = ({ children, user, logout }: AppLayoutProps) => {
  return !!user ? (
    <AuthLayout user={user} logout={logout}>
      {children}
    </AuthLayout>
  ) : (
    <PublicLayout>{children}</PublicLayout>
  );
};

export default AppLayout;
