import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import user from "../../../../assets/img/user.png";
import { useMenu } from "../useMenu";
import * as routeName from "../../../../routes/routeNameAuthType";

interface MenuLoggedProps {
	changeRoute: (name: string) => void;
	logout: () => void;
	title: string;
}
const MenuLogged = ({ changeRoute, logout, title }: MenuLoggedProps) => {
	const { menuList } = useMenu();
	return (
		<Nav className="nav-item-content">
			<div className="nav-item-drop">
				{menuList
					.filter((ele: any) => !ele.hide)
					.map((ele: any) => (
						<div className="d-flex align-items-center me-4" key={ele.title}>
							{ele.icon && <i className={`${ele.icon} text-white me-1`}></i>}

							<NavDropdown
								id={ele.id}
								title={ele.title}
								>
								{ele.list
									.filter((el: any) => !el.hide)
									.map((el: any) => (
										<NavDropdown.Item key={el.action.route} style={{ width: "260px" }}
											onClick={() => changeRoute(el.action.route)}>
											<Row>
												<Col xs="2">
													{el.icon && <i className={`${el.icon} me-1`}></i>}
												</Col>
												<Col xs="10">{el.label}</Col>
											</Row>
										</NavDropdown.Item>
									))}
							</NavDropdown>
						</div>
					))}
			</div>
			<div className="nav-user-drop">
				<img className="img-user" src={user} alt="logo" />
				<NavDropdown className="" title={title} id="passwordChange">
					<NavDropdown.Item onClick={() => changeRoute(routeName.usersChangePw)}>Cambio password</NavDropdown.Item>
					<NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
				</NavDropdown>
			</div>
		</Nav>
	);
};

export default MenuLogged;
