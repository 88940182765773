// A mock function to mimic making an async request for data
import http from "../../../utils/http";
import {Newsstand, Package, Sender} from "./model/Package";
import {PackageState} from "./packageSlice";
import {User} from "../../../app/auth/model/User";

//export const insertPackagesCall = (data: Package[]) => http.post('postinsertcollinote', data)
export const insertPackagesCall = (data: Package[]) => http.post('postinsertcolliimporto', data)
export const getPackageStateCall = () => http.get<{data: PackageState[]}>('getcollistate')
export const getSenderCall = () => http.get<{data: Sender}>('getmittente')
export const getRecipientCall = (user: Partial<User> = {}) => http.get<{data: Newsstand[], status: number}>(`getdestinatari/ARG_KCODGIT=${user.user_girotipo || 0}/ARG_KCODGIR=${user.user_giro || 0}`)
export const getPackagesCall = ({COL_DATACONSEGNA, COL_RIV_KCODRIV}: {COL_DATACONSEGNA: string; COL_RIV_KCODRIV: string}) => http.get<{data: any, status: number}>(`getcolliapp/COL_RIV_KCODRIV=${COL_RIV_KCODRIV}/COL_DATACONSEGNA=${COL_DATACONSEGNA}`)
export const getPackagesAvmCall = ({COL_DATACONSEGNA, COL_RIV_KCODRIV}: {COL_DATACONSEGNA: string; COL_RIV_KCODRIV: string}) => http.get<{data: any, status: number}>(`getcolliavm/COL_RIV_KCODRIV=${COL_RIV_KCODRIV}/COL_DATACONSEGNA=${COL_DATACONSEGNA}`)
export const changeStatePackageCall = (data: any) => http.post<{data: any, status: number}>(`postinsertcollistateweb`, data)
export const changeStateAdminPackageCall = (data: any) => http.post<{data: any, status: number}>(`postinsertcollistatewebammi`, data)
export const changeStateAllPackageCall = (data: any) => http.post<{data: any, status: number}>(`postinsertcolliresoweb`, data)
export const changeStateAllAdminPackageCall = (data: any) => http.post<{data: any, status: number}>(`postinsertcolliresowebammi`, data)
