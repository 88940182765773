import React from "react";
import { Col, Container } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
const FooterLayout = () => {
	return (
		<div className="footer hide_print">
			<Container>
				<Row>
					<Col xs="12">
						<div className="d-flex justify-content-between">
							<small>© 2021 Copyright Init1 Tech Srl - Tutti i diritti riservati - Ver.1.0.4</small>
							<small>
								<a href="mailto:assistenza@dispeavm.it">
									Email assistenza: assistenza@dispeavm.it
								</a>
							</small>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default FooterLayout;
