export const permissionsData = {
    crudPackage: {
        id: '1'
    },
    crudUser: {
        id: '2'
    },
    controlUser: {
        id: '3'
    },
    controlPackageManagement: {
        id: '4'
    },
    viewStores: {
        id: '5'
    },
    viewMaps: {
        id: '6'
    },
    controlTransporter: {
        id: '7'
    },
    checkPackage: {
        id: '8'
    },
    checkDataAvm: {
        id: '9'
    },
    disPermissions: {
        id: '10'
    }
}


export const issetPermissions = (
    permissionsUser: string[] = [],
    permissions: string[] = [],
    or: boolean = false
) => {
    return or
        ? issetOnePermission(permissionsUser, permissions)
        : issetAllPermission(permissionsUser, permissions);
};

const issetAllPermission = (
    permissionsUser: string[] = [],
    permissions: string[] = []
) => {
    return permissions.reduce(
        (control: boolean, perm) =>
            permissionsUser.includes(perm) ? control : false,
        true
    );
};

const issetOnePermission = (
    permissionsUser: string[] = [],
    permissions: string[] = []
) => {
    return permissions.reduce(
        (control: boolean, perm) =>
            permissionsUser.includes(perm) ? true : control,
        false
    );
};
