import {useSelector} from "react-redux";
import {selectUserPermissions} from "./authSlice";
import {issetPermissions} from "./permissionsData";

export function usePermissions() {
    const permissions = useSelector(selectUserPermissions);
    const issetPermission = (permissionList: string[], or: boolean = false) => {
        return issetPermissions(permissions, permissionList, or);
    };
    return {
        issetPermission,
    };
}
