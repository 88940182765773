import React from "react";

import { ToastProps } from "../models/ToastProps";
import ToastPropsStyle from "../../../toast/models/ToastProps";
import ToastMsg from "../../../toast/ToastMsg";

const styleError: ToastPropsStyle = {
	style: { color: "#fff", bgColor: "#a10000" },
	icon: { color: "white", ico: "fas fa-exclamation-triangle" },
};

const ToastError = ({
	className,
	title,
	body,
	closeAction,
	...rest
}: ToastProps) => {
	return (
		<ToastMsg
			className="shared-toastError"
			toastStyle={styleError}
			title={title}
			body={body}
			closeAction={() => closeAction && closeAction()}></ToastMsg>
	);
};

export default ToastError;
