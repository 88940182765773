import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {removeError, selectErrors} from "../../app/errors/errorSlice";
import ToastListError from "../../components/shared/errors/toast/toastListError/ToastListError";

const ErrorView = () => {
  const list = useSelector(selectErrors);
  const dispatch = useDispatch();
  return (
      <ToastListError list={list} closeAction={(id: number | undefined) => dispatch(removeError(id))}/>
  );
};

export default ErrorView;
