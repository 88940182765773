import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.scss';
import Loader from "./components/shared/Loader";
import PublicRoute from "./components/routes/PublicRoute";
import AuthRoute from './components/routes/AuthRoute';
import AppLayout from './components/core/app-layout/AppLayout';
import {useDispatch, useSelector} from "react-redux";
import {autoLogin, logoutAction, selectIsLogged, selectUser} from "./app/auth/authSlice";
import LoaderFullScreen from "./components/shared/loader/loaderFullscreen/LoaderFullScreen";
import ErrorView from './app/errors/ErrorView';


function App() {
    const isLogged = useSelector(selectIsLogged);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(autoLogin())
    }, [])
  return (
      <Router>
        <LoaderFullScreen />
        <ErrorView />
        <AppLayout user={user} logout={() => dispatch(logoutAction())}>
          <Switch>
            {isLogged === undefined || (isLogged && !user) ? (
                <></>
            ) : (
                <>
                  <Route path="/">
                    <PublicRoute isLogged={isLogged} />
                  </Route>

                  <Route path="/">
                    <AuthRoute isLogged={!!user} />
                  </Route>
                </>
            )}
            )
          </Switch>
        </AppLayout>
      </Router>
  );
}

export default App;
