import {store} from "../app/store";
import {logoutAction} from "../app/auth/authSlice";


export default (err: any) => {
    const dispatch = store.dispatch;

    if(!err.response.config.url.includes('logout') && ((err?.response?.data?.errors === 401) || (err?.response?.status === 401))){
        console.log('processo di logout');
        dispatch(logoutAction())
    }
    return Promise.reject(err);
}
