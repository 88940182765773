import { Routes } from "../components/routes/models/routes";
import * as routeName from "./routeNameAuthType";
import {permissionsData} from "../app/auth/permissionsData";


const routes: Routes = {
  list: [
    {
      path: "/home",
      component: "features/auth/home/Home",
      name: routeName.home,
    },
    {
      path: "/user/create",
      component: "features/auth/user/store/CreateUser",
      name: routeName.usersStore,
      permissions: [permissionsData.crudUser.id]
    },
    {
      path: "/change-pw",
      component: "features/auth/user/change_pw/ChangePw",
      name: routeName.usersChangePw
    },
    {
      path: "/users",
      component: "features/auth/user/index/IndexUsers",
      name: routeName.usersIndex,
      permissions: [permissionsData.crudUser.id]
    },
    {
      path: "/package/create",
      component: "features/auth/package/store/CreatePackage",
      name: routeName.packageStore,
      permissions: [permissionsData.crudPackage.id]
    },
    {
      path: "/packages",
      component: "features/auth/package/index/IndexPackages",
      name: routeName.packageIndex,
      permissions: [permissionsData.controlTransporter.id, permissionsData.controlPackageManagement.id],
      or: true
    },
    {
      path: "/transporter",
      component: "features/auth/transporter/AssignTransporter",
      name: routeName.transporterAssign,
      permissions: [permissionsData.controlPackageManagement.id, permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],
      or: true

    },
    {
      path: "/newsstand",
      component: "features/auth/newsstand/index/IndexNewsstand",
      name: routeName.newsstandIndex,
      permissions: [permissionsData.crudPackage.id, permissionsData.disPermissions.id],
      or: true

    },
    {
      path: "/special",
      component: "features/auth/newsstand/special/SpecialNewsstand",
      name: routeName.special,
      permissions: [permissionsData.crudPackage.id]
    },
    {
      path: "/island",
      component: "features/auth/newsstand/island/IslandNewsstand",
      name: routeName.island,
      permissions: [permissionsData.crudPackage.id]
    },
    {
      path: "/islandPeso",
      component: "features/auth/newsstand/islandPeso/IslandNewsstandPeso",
      name: routeName.islandPeso,
      permissions: [permissionsData.crudPackage.id]
    },
    {
      path: "/islandPrice",
      component: "features/auth/newsstand/islandPrice/IslandPriceNewsstand",
      name: routeName.islandPrice,
      permissions: [permissionsData.checkDataAvm.id]
    },
    {
      path: "/transporterMap",
      component: "features/auth/map/TransporterMap",
      name: routeName.mapTransporter,
      permissions: [permissionsData.crudPackage.id]
    },
    {
      path: "/statistics",
      component: "features/auth/statistic/index/IndexStatistics",
      name: routeName.statisticIndex,
      permissions: [permissionsData.controlPackageManagement.id]
    },
    {
      path: "/confirm",
      component: "features/auth/confirm/index/IndexConfirm",
      name: routeName.confirmIndex,
      permissions: [permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],
      or: true
    },
    {
      path: "/check",
      component: "features/auth/check/index/IndexCheck",
      name: routeName.checkIndex,
      permissions: [permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],
      or: true
    },
    {
      path: "/notReceived",
      component: "features/auth/notreceived/index/IndexNotReceived",
      name: routeName.notReceivedIndex,
      permissions: [permissionsData.checkDataAvm.id],
    }
  ],
  redirect: routeName.redirectPath,
};
export default routes;
