import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./config/i18n";
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import authInterceptor from "./interceptors/authInterceptor";
import status401Interceptor from "./interceptors/status401Interceptor";
import {decrementN, incrementN} from "./components/shared/loader/redux/loadSlice";
import successInterceptor from "./interceptors/successInterceptor";
import errorInterceptor from "./interceptors/errorInterceptor";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
axios.interceptors.request.use(authInterceptor);
axios.interceptors.response.use((config: any) => config, status401Interceptor);
axios.interceptors.request.use((config) => {
    store.dispatch(incrementN());
    return config;
});
axios.interceptors.response.use(
    (config: any) => {
        store.dispatch(decrementN());
        return config;
    },
    (err) => {
        store.dispatch(decrementN());
        return Promise.reject(err);
    }
);
// axios.interceptors.response.use(successInterceptor, errorInterceptor);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
