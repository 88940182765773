import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useRoute } from "../../../../utils/useRoute";
import logo from "../../../../assets/img/logo.png";

const HeaderLayout = ({
	nav,
	menuLogged,
}: {
	nav?: React.ReactNode;
	menuLogged: React.ReactNode;
}) => {
	const { changeRoute } = useRoute();
	return (
		<Navbar fixed="top" expand="md" className="navGradient hide_print">
			<Container>
				<Navbar.Brand onClick={() => changeRoute("home")}>
					<img className="logo-brand" src={logo} alt="logo" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="w-100">{menuLogged}</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default HeaderLayout;
