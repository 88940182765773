import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AppThunkError,
  ErrorModelState,
  ErrorState,
  ErrorStateWithoutd,
  InitialStateErrors
} from "./ErrorModelState";
import {AppThunk} from "../store";
import {errorType} from "../../components/shared/errors/toast/models/ToastProps";



const initialState: InitialStateErrors = {
  errors: []
}

export const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      state.errors.push(getErrorObj(action.payload))
    },
    setAddSuccessMessage: (state, action: PayloadAction<ErrorState>) => {
      state.errors.push(action.payload)
    },
    addWarningMessage: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      state.errors.push(getErrorObj(action.payload, 'warning'))
    },
    addErrorMessage: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      state.errors.push(getErrorObj(action.payload, 'error'))
    },
    addUnauthorizedErrorMessage: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      if(!state.errors.find(el => el.type === 'unauthorized'))
        state.errors.push(getErrorObj(action.payload, 'unauthorized'))
    },
    resetErrors: state => {
      state.errors = []
    },
    removeError: (state, action: PayloadAction<number | undefined>) => {
      state.errors = state.errors.filter(el => el.id !== action.payload)
    }
  }
})

export const {
  addError,
  resetErrors,
  removeError,
  setAddSuccessMessage,
  addErrorMessage,
  addWarningMessage,
  addUnauthorizedErrorMessage
} = errorSlice.actions

export const getErrorObj = (error: ErrorStateWithoutd, type?: errorType) => {
  const id: number = Date.now() * Math.round(Math.random() * 100)
  return { ...error, id, type }
}

export const addSuccessMessage = (
  error: ErrorStateWithoutd
): AppThunkError => dispatch => {
  const obj: ErrorState = getErrorObj(error, 'success')
  dispatch(setAddSuccessMessage(obj))
  setTimeout(() => {
    dispatch(removeError(obj.id))
  }, 4000)
}
export const addMessageSpeed = (
    title: string,
    success: boolean = false
): AppThunkError => (dispatch, getState) => {

    success ? dispatch(addSuccessMessage({title})) :  dispatch(addErrorMessage({title}))
}
export const addSuccessMessageSpeed = (
    title: string
): AppThunkError => (dispatch, getState) => {
      dispatch(addMessageSpeed(title, true))
}


export const selectErrors = (state: ErrorModelState) => state.errors.errors

export default errorSlice.reducer
