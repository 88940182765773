import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../../app/store";
import {getPackageStateCall} from "./packageAPI";

export interface PackageState{
  ID: string;
  COS_STATE: string
}

export interface PackageSliceState {
  states: PackageState[]
}

const initialState: PackageSliceState = {
  states: []
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setStates: (state, action: PayloadAction<PackageState[]>) => {
      state.states = action.payload;
    }
  },
});

export const {
  setStates
} = packageSlice.actions;

export const setPackageStatesAction = (): AppThunk => (dispatch) => {
  getPackageStateCall().then(res => {
    dispatch(setStates(res.data))
  })
};

export const selectStates = (state: RootState) => state.package.states;


export default packageSlice.reducer;
