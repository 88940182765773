import React from "react";
import { User } from "../../../../app/auth/model/User";
import { useRoute } from "../../../../utils/useRoute";
import { useDispatch } from "react-redux";
import HeaderLayout from "./HeaderLayout";
import MenuLogged from "./MenuLogged";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
interface HeaderProps {
	user: User | undefined;
	logout: () => void;
}
const Header = ({ logout, user }: HeaderProps) => {
	const { changeRoute } = useRoute();
	const changeRouteWithReset = (name: string) => {
		changeRoute(name);
	};
	return (
		<>
			<HeaderLayout
				menuLogged={
					<MenuLogged
						changeRoute={changeRouteWithReset}
						logout={logout}
						title={user?.username + ""}
					/>
				}
			/>
		</>
	);
};

export default Header;
