import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import ToastPropsStyle from "./models/ToastProps";

interface ToastProps {
	className?: string;
	toastStyle: ToastPropsStyle;
	title: string;
	body?: string;
	showToast?: boolean;
	closeAction: () => void;
}
const ToastMsg = ({
	className,
	toastStyle,
	title,
	body,
	showToast = true,
	closeAction,
}: ToastProps) => {
	const [animationState, setAnimationState] = useState("out");
	useEffect(() => {
		setTimeout(() => {
			setAnimationState("in");
		}, 300);
	}, []);

	const closeHandler = () => {
		setAnimationState("out");
		setTimeout(() => {
			closeAction();
		}, 800);
	};

	return (
		<div className={`shared-toast ${animationState} ${className}`}>
			<Toast
				show={showToast}
				onClose={closeHandler}
				style={{
					backgroundColor: toastStyle.style.bgColor,
					color: toastStyle.style.color,
				}}>
				<Toast.Header
					style={{
						backgroundColor: toastStyle.style.bgColor,
						color: toastStyle.style.color,
					}}>
					<i
						className={`${toastStyle.icon.ico} me-2`}
						style={{ color: toastStyle.icon.color }}></i>
					<strong className="me-auto">{title}</strong>
				</Toast.Header>
				{body && <Toast.Body>{body}</Toast.Body>}
			</Toast>
		</div>
	);
};

export default ToastMsg;
