import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoaderFullScreen from "../shared/loader/loaderFullscreen/LoaderFullScreen";
import { Routes } from "./models/routes";

interface DynamicRouteProps {
  routes: Routes;
}
const DynamicRoute = ({ routes }: DynamicRouteProps) => {
  return (
    <React.Suspense fallback={<LoaderFullScreen></LoaderFullScreen>}>
      <Switch>
        {routes.list.map((ele) => {
          const route = lazy(() => import("../../" + ele.component));
          return (
            <Route
              key={ele.path}
              exact
              path={ele.path}
              component={route}
            ></Route>
          );
        })}
        {routes.redirect ? <Redirect from="*" to={routes.redirect} /> : null}
      </Switch>
    </React.Suspense>
  );
};

export default DynamicRoute;
