import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loadReducer from "../components/shared/loader/redux/loadSlice";
import authReducer from "./auth/authSlice";
import packageReducer from "../features/auth/package/packageSlice";
import errorReducer from "./errors/errorSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    load: loadReducer,
    auth: authReducer,
    errors: errorReducer,
    package: packageReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
