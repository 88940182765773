import React from "react";
import BoxFullScreen from "../../boxFullScreen/BoxFullScreen";

import Loader from "../loader/Loader";
import { useSelector } from "react-redux";
import { selectActiveLoad, selectActiveManualLoad } from "../redux/loadSlice";

interface LoaderProps {
  children?: React.ReactNode;
  className?: string;
}

const LoaderFullScreen = ({ children, className }: LoaderProps) => {
  const show = useSelector(selectActiveLoad);
  const showManual = useSelector(selectActiveManualLoad);
  return (
    <BoxFullScreen show={show || showManual} loader={true}>
      <div className="loader">
        <Loader></Loader>
      </div>
    </BoxFullScreen>
  );
};
export default LoaderFullScreen;
