export const home = "Home";
export const usersIndex = "users.index";
export const usersStore = "users.store";
export const usersChangePw = "users.changePw";
export const packageIndex = "package.index";
export const packageStore = "package.store";
export const transporterAssign = "transporter.assign";
export const statisticIndex = "statistic.index";
export const checkIndex = "check.index";
export const notReceivedIndex = "notReceived.index";
export const confirmIndex = "confirm.index";
export const newsstandIndex = "newsstandIndex.index";
export const special = "newsstandIndex.special";
export const island = "newsstandIndex.island";
export const islandPeso = "newsstandIndex.islandPeso";
export const islandPrice = "newsstandIndex.islandPrice";
export const mapTransporter = "newsstandIndex.mapTransporter";
export const redirectPath = "/home";
