import { usePermissions } from "../../../app/auth/usePermissions";
import { permissionsData } from "../../../app/auth/permissionsData";
import * as routeName from "../../../routes/routeNameAuthType";

interface voiceMenu {
	label: string;
	icon?: string;
	action: any;
	hide?: boolean;
}

interface MenuList {
	title: string;
	icon?: string;
	id: string;
	hide?: boolean;
	list: voiceMenu[];
}

export function useMenu() {
	const { issetPermission } = usePermissions();
	const menuList: MenuList[] = [
		{
			title: "Gestione Utenti",
			icon: "fas fa-users",
			id: "userGroup",
			hide: !issetPermission([permissionsData.crudUser.id]),
			list: [
				{
					label: "Lista Utenti",
					icon: "far fa-address-book",
					action: { route: routeName.usersIndex },
				},
				{
					label: "Nuovo Utente",
					icon: "fas fa-user-plus",
					action: { route: routeName.usersStore },
				},
			],
		},
		{
			title: "Gestione colli",
			icon: "fas fa-box-open",
			id: "managementPackage",
			list: [
				{
					label: "Lista Colli",
					icon: "fas fa-list",
					action: { route: routeName.packageIndex },
					hide: !issetPermission(
						[
							permissionsData.controlTransporter.id,
							permissionsData.controlPackageManagement.id,
						],
						true
					),
				},
				{
					label: "Inserimento Colli",
					icon: "far fa-plus-square",
					action: { route: routeName.packageStore },
					hide: !issetPermission([permissionsData.crudPackage.id]),
				},
				{
					label: "Verifica Colli",
					icon: "fas fa-check",
					action: { route: routeName.checkIndex },
					hide: !issetPermission([permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],true	),
				},
				{
					label: "Non Consegnati-Resi",
					icon: "fas fa-bell",
					action: { route: routeName.notReceivedIndex },
					hide: !issetPermission([permissionsData.checkDataAvm.id]),
				},
				{
					label: "Assegna trasportatore",
					icon: "fas fa-truck",
					action: { route: routeName.transporterAssign },
					//hide: !issetPermission([permissionsData.controlPackageManagement.id, permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],true	),
					hide: !issetPermission([permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],true	),
				},
				{
					label: "Statistiche",
					icon: "fas fa-grip-horizontal",
					action: { route: routeName.statisticIndex },
					hide: !issetPermission([permissionsData.controlPackageManagement.id]),
				},
				{
					label: "Amministrazione Colli",
					icon: "fas fa-users-cog",
					action: { route: routeName.confirmIndex },
					hide: !issetPermission([permissionsData.checkDataAvm.id, permissionsData.disPermissions.id],true	),
				}
			],
		},
		{
			title: "Gestione Ricevitorie",
			icon: "fas fa-store",
			id: "userGroup",
			hide: !issetPermission([permissionsData.crudPackage.id, permissionsData.checkDataAvm.id, permissionsData.disPermissions.id], true),
			list: [
				{
					label: "Anagrafica generale",
					icon: "fas fa-address-book",
					action: { route: routeName.newsstandIndex },
					hide: !issetPermission([permissionsData.crudPackage.id, permissionsData.disPermissions.id],true	),
				},
				{
					label: "Analisi speciali",
					icon: "fas fa-people-arrows",
					action: { route: routeName.special },
					hide: !issetPermission([permissionsData.checkDataAvm.id]),
				},
				{
					label: "Analisi TF/CS-isole",
					icon: "fas fa-map-marked-alt",
					action: { route: routeName.island },
					hide: !issetPermission([permissionsData.controlPackageManagement.id, permissionsData.checkDataAvm.id], true),
				},
				{
					label: "Analisi TF/CS-isole Peso",
					icon: "fas fa-balance-scale",
					action: { route: routeName.islandPeso },
					hide: !issetPermission([permissionsData.controlPackageManagement.id, permissionsData.checkDataAvm.id], true),
				},
				{
					label: "Conteggi TF/CS-isole",
					icon: "fas fa-euro-sign",
					action: { route: routeName.islandPrice },
					hide: !issetPermission([permissionsData.checkDataAvm.id]),
				},
				{
					label: "Mappa Traportatore",
					icon: "fas fa-map",
					action: { route: routeName.mapTransporter },
					hide: !issetPermission([permissionsData.checkDataAvm.id]),
				}
			],
		},
	];
	return {
		menuList: menuList
			.filter((ele) => !ele.hide)
			.map((ele) => ({ ...ele, list: ele.list.filter((el) => !el.hide) })),
	};
}
