import React from "react";
import logo from "../../../../../assets/img/logo.png";

const PublicLayout = ({ children }: { children: React.ReactNode }) => {
	return (
		<div id="publicLayout">
			<div className="login-content">
				<div className="img-content">
					<img className="logo-brand" src={logo} alt="logo" />
				</div>
				<div className="img-content">
					<p><b>DispeAvm</b></p>
				</div>
				{children}
			</div>
		</div>
	);
};

export default PublicLayout;
