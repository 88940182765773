import React from "react";
import authRoute from "../../routes/auth";
import DynamicRoute from "./DynamicRoute";
import {usePermissions} from "../../app/auth/usePermissions";

const AuthLayout = () => {
  const { issetPermission } = usePermissions();
  const filterRoute = {
    ...authRoute,
    list: authRoute.list.filter((ele) =>
      ele.permissions && ele.permissions.length
        ? issetPermission(ele.permissions, ele.or)
        : true
    ),
  };
  return (
    <div className="auth">
        <DynamicRoute routes={filterRoute} />
    </div>
  );
};

export default AuthLayout;
