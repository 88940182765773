import React from "react";
import Header from "./Header";
import { Container } from "react-bootstrap";
import { User } from "../../../../app/auth/model/User";
import FooterLayout from "./FooterLayout";

interface AuthLayoutProps {
  children: React.ReactNode;
  user: User | undefined;
  logout: () => void;
}
const AuthLayout = ({ user, logout, children }: AuthLayoutProps) => {
  return (
    <>
      <div id="authLayout">
        <div className="containerApp">
          <Header user={user} logout={logout} />
          <div className="main-auth">
            <Container>
              <main>{children}</main>
            </Container>
          </div>
        </div>
      </div>
      <FooterLayout></FooterLayout>
    </>
  );
};

export default AuthLayout;
