import { Routes } from "../components/routes/models/routes";
export const routes: Routes = {
  list: [
    {
      path: "/",
      component: "features/public/login/Login",
      name: "Login",
    }
  ],
  redirect: "/",
};

export default routes;
