import authRoutes from "../routes/auth";
import publicRoutes from "../routes/public";
import { useHistory } from "react-router";
export function useRoute() {
  const history = useHistory();
  const routes = [...authRoutes.list, ...publicRoutes.list];
  const changeRoute = (
    name: string,
    data?: { [key: string]: string },
    query?: { [key: string]: string }
  ) => {
        let path = getRoute(name);
        if (path.includes(":")) {
          data &&
            Object.keys(data).forEach((ele) => {
              path = path.replace(":" + ele, data[ele]);
            });
        }
        if (query) {
          const values = Object.keys(query).reduce((newObj: any, ele) => {
            if (query[ele] !== undefined) {
              newObj[ele] = query[ele];
            }
            return newObj;
          }, {});
          path = `${path}?${new URLSearchParams(values).toString()}`;
        }
        history.push(path);
  };

  const findRoute = (name: string) => {
    return routes.find((ele) => ele.name === name);
  };
  const getRoute = (name: string): string => {
    const rt = findRoute(name);
    return rt ? rt.path : "/";
  };

  return {
    changeRoute,
  };
}
